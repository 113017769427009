import resolve from 'core/resolver/resolve';

import { denormalizeData, filterRequiredParams } from 'core/utils/api';
import resolveRelationships from 'core/utils/relationships';

import Card1 from 'site/cards/Card1';
import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import Card5 from 'site/cards/Card5';
import Card6 from 'site/cards/Card6';
import CardHorizontal from 'site/cards/CardHorizontal';


const selectionRelationships = resolveRelationships(['read_more'], {});
const card2Params = {
  fields: filterRequiredParams([Card2], 'fields'),
};
const card6Params = {
  fields: filterRequiredParams([Card6], 'fields'),
  include: filterRequiredParams([Card6], 'include'),
};
export const reviewsCommonParams = {
  rubric_root: 'review',
  visibility: 'main_page',
  fields: filterRequiredParams([Card3], 'fields'),
  include: filterRequiredParams([Card3], 'include'),
};


export default resolve({
  firstBlock: ({ bebopApi, consoleError }) => {
    const limit = 4;
    const mainTopicsInclude = 'image,review';
    const mainTopicsFields = filterRequiredParams([Card1, Card3], 'fields');

    return bebopApi
      .getTopics({
        list: 'main',
        sort: 'list',
        limit,
        include: mainTopicsInclude,
        fields: mainTopicsFields,
      })
      .then(rawMainListTopics => {
        const mainListTopics = denormalizeData(rawMainListTopics);
        const excludedIds = mainListTopics.map(({ id }) => id).join(',');

        return Promise
          .allSettled([
            bebopApi
              .getTopics({
                limit: 11,
                topic_type: 'news',
                visibility: 'main_page',
                excluded_ids: excludedIds,
                ...card2Params,
              })
              .then(denormalizeData)
              .catch(() => consoleError('news', null)),

            mainListTopics.length >= limit
              ? mainListTopics
              : bebopApi
                .getTopics({
                  topic_type: 'article,gallery',
                  limit: limit - mainListTopics.length,
                  include: mainTopicsInclude,
                  excluded_ids: excludedIds,
                  fields: mainTopicsFields,
                })
                .then(rawRestMainTopics => {
                  const restMainTopics = denormalizeData(rawRestMainTopics);
                  return mainListTopics.concat(restMainTopics);
                })
                .catch(() => consoleError('first block additional topics', mainListTopics)),
          ])
          .then(([newsResult, mainTopicsResult]) => ({
            newsTopics: newsResult.value,
            mainTopics: mainTopicsResult.value,
          }));
      })
      .catch(() => consoleError('main list topics', null));
  },

  selection: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      limit: 1,
      list: 'selection',
      sort: 'list',
      include: 'read_more,tags,image',
      fields: 'headline,published_at,link,ad_label',
    })
    .then(async rawSelections => {
      const selection = denormalizeData(rawSelections)?.[0];
      const selectionTopicIds = selectionRelationships(selection)?.read_more?.topic_ids || [];

      if (selectionTopicIds?.length < 1) return {};

      const rawTopics = await bebopApi.getTopics({
        ids: selectionTopicIds.slice(0, 6),
        ...card2Params,
      });

      return {
        selection,
        topics: denormalizeData(rawTopics),
      };
    })
    .catch(() => consoleError('selection list', {})),

  mostCommentable: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      limit: 6,
      sort: '-comments_count',
      fields: 'headline,published_at,link,ad_label',
    })
    .then(denormalizeData)
    .catch(() => consoleError('most commentable', [])),

  experts: ({ bebopApi, consoleError }) => {
    const listLimit = 3;
    const include = 'image,authors,rubric';
    const fields = filterRequiredParams([CardHorizontal], 'fields');

    return bebopApi
      .getTopics({
        list: 'experts',
        sort: 'list',
        limit: listLimit,
        include,
        fields,
      })
      .then(rawListTopics => {
        const listTopics = denormalizeData(rawListTopics);

        if (listTopics.length >= listLimit) {
          return listTopics;
        }

        const listIds = listTopics.map(({ id }) => id);

        return bebopApi
          .getTopics({
            rubric: 'experts',
            visibility: 'main_page',
            limit: listLimit - listTopics.length,
            include,
            fields,
            excluded_ids: listIds.join(','),
          })
          .then(rawOtherTopics => {
            return listTopics.concat(
              denormalizeData(rawOtherTopics)
            );
          })
          .catch(() => consoleError('experts list additional topics', listTopics));
      })
      .catch(() => consoleError('experts list', []));
  },

  lifehacks: ({ bebopApi, isMobile, consoleError }) => {
    return bebopApi
      .getTopics({
        visibility: 'main_page',
        rubric_root: 'lifehack',
        limit: isMobile ? 4 : 3,
        fields: filterRequiredParams([CardHorizontal], 'fields'),
        include: filterRequiredParams([CardHorizontal], 'include'),
      })
      .then(denormalizeData)
      .catch(() => consoleError('lifehacks block', []));
  },

  videos: ({ bebopApi }) => {
    const listLimit = 4;
    const include = 'image,rubric';
    const fields = filterRequiredParams([Card3, Card5], 'fields');

    return bebopApi
      .getTopics({
        list: 'video',
        sort: 'list',
        limit: listLimit,
        include,
        fields,
      })
      .then(rawListTopics => {
        const listTopics = denormalizeData(rawListTopics);
        if (listTopics.length >= listLimit) {
          return listTopics;
        }

        const listIds = listTopics.map(({ id }) => id);

        return bebopApi
          .getTopics({
            tag: 'video',
            limit: listLimit - listTopics.length,
            include,
            excluded_ids: listIds.join(','),
            fields,
            visibility: 'main_page',
          })
          .then(rawAllTopics => {
            return listTopics.concat(
              denormalizeData(rawAllTopics)
            );
          });
      });
  },

  numberOfTheDay: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      list: 'number',
      visibility: 'main_page',
      limit: 1,
      ...card6Params,
    })
    .then(rawData => denormalizeData(rawData)?.[0])
    .catch(() => consoleError('number of the day', null)),

  historyNumber: ({ bebopApi, consoleError }) => bebopApi
    .getTopics({
      list: 'history',
      visibility: 'main_page',
      limit: 1,
      ...card6Params,
    })
    .then(rawData => denormalizeData(rawData)?.[0])
    .catch(() => consoleError('history number', null)),

  reviews: ({ bebopApi, isDesktop, consoleError }) => bebopApi
    .getTopics({
      ...reviewsCommonParams,
      limit: isDesktop ? 6 : 4,
    })
    .then(denormalizeData)
    .catch(() => consoleError('reviews', [])),
});
