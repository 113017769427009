import { Route } from 'core/libs/router';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import { Mobile, Desktop } from 'core/components/breakpoint';

import LegalPage from 'core/components/LegalPage';
import RulesRecommenders from 'core/components/RulesRecommenders';
import PersonalAccount from 'core/components/PersonalAccount';

import Layout from 'site/components/Layout';

import {
  Fullscreen as DesktopFullscreen,
} from 'site/components/Ads/desktop';

import GlobalNativeStyles from 'site/components/Ads/styles/global';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from 'site/components/Ads/mobile';

import Main from 'site/pages/main';
import Tag from 'site/pages/tag';
import Search from 'site/pages/search';
import Topic from 'site/pages/topic';
import RubricPage from 'site/pages/rubric';
import About from 'site/pages/about';
import Authors from 'site/pages/Authors';
import Author from 'site/pages/Author';
import News from 'site/pages/news';
import Comments from 'site/pages/comments';
import LeaderBoard from 'site/pages/leaderBoard';

import configResolver from 'site/shared-app-config';
import { newsBaseUrl } from 'site/constants';


export default function Site() {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver();

  return (
    <App {...config}>
      <Layout>
        <BaseRoutes
          tags={Tag}
          main={Main}
          topic={Topic}
          about={About}
          search={Search}
          rubrics={RubricPage}
          author={Author}
          authors={Authors}
          comments={Comments}
          profile={PersonalAccount}
          legal={LegalPage}
          leaderBoard={LeaderBoard}
          rulesRecommenders={RulesRecommenders}
        >
          <Route exact
            path={newsBaseUrl + '/:year?/:month?/:day?'}
            component={News}
          />
        </BaseRoutes>
      </Layout>

      <Desktop>
        <DesktopFullscreen />
        <GlobalNativeStyles />
      </Desktop>

      <Mobile>
        <Rich />
        <MobileFullscreen />
      </Mobile>
    </App>
  );
}
