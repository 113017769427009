import color from 'core/libs/color';
import deepmerge from 'core/libs/deepmerge';

import bindPropsHOC from 'core/components/bindProps';

import Search from 'site/icons/Search';
import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';
import Comments from 'site/icons/Comments';
import CloseIcon from 'site/icons/Close';
import ArrowLeftBold from 'site/icons/ArrowLeftBold';
import ArrowRightBold from 'site/icons/ArrowRightBold';
import SubscribeLogo from 'site/icons/SubscribeLogo';
import DefaultAvatar from 'site/icons/DefaultAvatar';

import Logo from 'site/components/Logo';

import StoryTopicCard from 'site/cards/StoryTopicCard';

import fonts from './fonts';

import { MOBILE, DESKTOP } from 'core/components/breakpoint/values';
import {
  SIDE_INDENT,
  VERTICAL_INDENT,
} from 'site/constants';

import Card3 from 'site/cards/Card3';

import { TopicContentMaxWidth, NegativeMobile } from 'core/components/Wrappers';

import notFoundImg from 'site/images/404.png';
import jsErrorImg from 'site/images/jsError.png';


const icons = {
  logo: Logo,
  search: Search,
  Video,
  Photo,
  Comments,
  DefaultAvatar,
};

const Card3Type1  = bindPropsHOC({ type: 1 })(Card3);


export default function baseTheme(atoms) {
  const layout = {
    indents: {
      top: VERTICAL_INDENT,
      right: SIDE_INDENT,
      bottom: VERTICAL_INDENT,
      left: SIDE_INDENT,
    },
  };

  const texts = {
    // Header страницы
    headline: {
      font: `700 32px/1.33 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    // Заголовки в теле топиков
    title2: {
      font: `700 24px/1.17 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    title3: {
      font: `700 20px/1.2 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    title4: {
      font: `600 18px/1.2 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    // Прочие стили текстов в теле топика
    body: {
      font: `400 18px/1.33 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    bodySmall: {
      font: `400 16px/1.22 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    lead: {
      font: `400 22px/33px ${fonts.text}`,
      letterSpacing: 'normal',
      color: color(atoms.colors.primary).alpha(0.6).string(),
      marginTop: '22px',
    },
    incut: {
      font: `600 18px/27px ${fonts.text}`,
    },
    opinion: {
      font: `300 italic 24px/30px ${fonts.text}`,
    },
    number: {
      body: {
        font: `300 italic 24px/28px ${fonts.text}`,
      },
      value: {
        font: `bold 150px/140px ${fonts.text}`,
        color: atoms.colors.active1,
      },
    },
    stickyPlateCard: {
      font: `bold 14px ${fonts.text}`,
      color: atoms.colors.primary,
    },
    get title1() {
      return this.headline;
    },
    // Заголовки на сайте, вне тела топиков
    h1: {
      font: `700 32px/1.5 ${fonts.text}`,
      letterSpacing: '-0.8px',
      color: atoms.colors.primary,
    },
    h2: {
      font: `700 32px/1.13 ${fonts.text}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
      margin: 0,
    },
    h3: {
      font: `700 32px/1.13 ${fonts.text}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    h4: {
      font: `700 24px/1.5 ${fonts.text}`,
      letterSpacing: 'normal',
      color: atoms.colors.active1,
    },
    // Заголовки блоков на главной
    display1: {
      font: `700 32px/1.25 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    // Заголовки в бороде
    display2: {
      font: `700 32px/normal ${fonts.text}`,
      color: atoms.colors.primary,
    },
    // Заголовки блоков в сайдбаре
    display3: {
      font: `700 24px/normal ${fonts.text}`,
      letterSpacing: '0.1px',
      color: atoms.colors.primary,
    },
    // Время, дата, рубрика
    caption1: {
      font: `400 16px/normal ${fonts.text}`,
      color: atoms.colors.hint,
    },
    // Подпись под фото и видео в топиках
    caption2: {
      font: `700 14px/1.14 ${fonts.text}`,
      color: atoms.colors.hint,
    },
    // Имя автора в начале и в конце топика, спонсорство
    caption4: {
      font: `400 18px/1.22 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    // Пункты меню и в футере
    caption5: {
      font: `700 20px/2 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    cardsMeta: {
      font: `400 14px/18px ${fonts.text}`,
      color: atoms.colors.gray600,
    },
    galleryInfo: {
      font: `600 14px/18px ${fonts.text}`,
      color: atoms.colors.primary400,
    },
  };

  const shapkaLink = {
    font: `16px/26px ${fonts.text}`,
  };

  const shapka = {
    link: {
      ...shapkaLink,
      padding: '5px 15px',
    },
    height: 110,
    padding: `30px ${SIDE_INDENT}px`,
    menu: {
      centered: true,
      navMargin: '0',
    },
    logo: {
      width: 200,
      height: 50,
      Icon: icons.logo,
    },
    dropdown: {
      link: {
        ...shapkaLink,
        padding: '5px 15px',
      },
      premiumTopic: {
        link: {
          ...shapkaLink,
          padding: '6px 20px 6px 0',
        },
      },
    },

    labelNoAds: {
      fontWeight: 'bold',
      color: '#fff',
      textTransform: 'none',
    },
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `700 20px/24px ${fonts.text}`,
      },
      info: {
        font: `400 16px/23px ${fonts.text}`,
      },
      link: {
        hover: {
          color: atoms.colors.active1,
        },
      },
    },
  };

  const scooter = {
    border: 'none',
    padding: `0 ${SIDE_INDENT}px`,
    social: {
      idle: {
        color: atoms.colors.content,
        background: atoms.colors.active1,
        border: '1px solid transparent',
      },
      hover: {
        color: atoms.colors.active1,
        background: 'transparent',
        border: `1px solid ${atoms.colors.active1}`,
      },
      margin: '0 0 0 20px',
      size: 40,
      iconSize: 15,
      borderRadius: 0,
    },
    logo: {
      width: '252px',
      height: '60px',
    },
    menuLink: {
      font: `700 20px/2 ${fonts.text}`,
    },
    menuItem: {
      pinnedSideItems: true,
    },
  };

  const gallery = {
    slide: {
      background: color(atoms.colors.primary).alpha(0.03).string(),
    },
  };

  const drum = {
    arrow: {
      Left: ArrowLeftBold,
      Right: ArrowRightBold,

      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
    },
  };

  const input = {
    const: {
      radius: 0,
      sizes: {
        extraSmall: {
          font: `12px/14px ${fonts.text}`,
        },
        small: {
          font: `16px/1.38 ${fonts.text}`,
        },
        medium: {
          font: `16px/21px ${fonts.text}`,
          height: '42px',
          padding: '10px 12px',
        },
      },
    },

    basic: {
      idle: {
        border: `1px solid ${atoms.colors.divider}`,
      },
      focused: {
        border: `1px solid ${atoms.colors.gray600}`,
      },
      hover: {
        border: `1px solid ${atoms.colors.gray600}`,
      },
      disabled: {
        background: atoms.colors.gray100,
        border: `1px solid ${atoms.colors.gray300}`,
        placeholderColor: atoms.colors.gray300,
      },
    },
  };

  const searchInput = {
    /**
     * Показывать ли полноценную кнопку рядом с инпутом вместо иконки
     * Можно переопределить свойством
     */
    withButton: false,
    placeholder: 'Поиск',

    icons: {
      Close: CloseIcon,
    },

    iconStyles: {
      withoutText: {
        idle: {
          color: atoms.colors.gray600,
        },

        hover: {
          color: atoms.colors.gray600,
        },

        focused: {
          color: atoms.colors.gray600,
        },

        disabled: {
          color: atoms.colors.gray300,
        },
      },

      withText: {
        idle: {
          color: atoms.colors.gray600,
        },

        hover: {
          color: atoms.colors.primary,
        },

        focused: {
          color: atoms.colors.primary,
        },

        disabled: {
          color: atoms.colors.gray300,
        },
      },
    },
  };

  const bouesque = {
    const: {
      size: 'medium',
    },
  };

  const link = {
    article: {
      idle: {
        decoration: 'none',
      },

      hover: {
        decoration: 'none',
      },

      active: {
        decoration: 'none',
      },

      visited: {
        decoration: 'none',
      },
    },

    secondary: {
      visited: {
        color: atoms.colors.primary,
      },
    },

    tertiary: {
      active: {
        color: atoms.colors.active1,
      },
      visited: {
        color: atoms.colors.primary,
      },
    },

    primary: {
      idle: {
        color: atoms.colors.primary,
      },

      hover: {
        color: atoms.colors.active1,
      },

      active: {
        color: atoms.colors.active1,
      },

      visited: {
        color: atoms.colors.gray700,
      },
    },
  };

  const button = {
    const: {
      radius: '0',
      sizes: {
        extraSmall: {
          font: `800 12px/14px ${fonts.text}`,
        },
        small: {
          font: `16px/1.38 ${fonts.text}`,
          width: '100px',
          height: '40px',
          padding: '9px 19px',
        },
        medium: {
          font: `16px/18px ${fonts.text}`,
          width: '120px',
          height: '42px',
          padding: '10px',
        },
        extraLarge: {
          font: `16px/22px ${fonts.text}`,
          width: '290px',
          height: '40px',
          padding: '10px 65px',
        },
      },
    },

    primary: {
      hover: {
        background: atoms.colors.active3,
      },

      active: {
        background: atoms.colors.active3,
      },

      disabled: {
        color: color(atoms.colors.primary).alpha(0.1).string(),
        background: color(atoms.colors.primary).alpha(0.05).string(),
      },
    },
    tertiary: {
      idle: {
        color: atoms.colors.primary,
        background: atoms.colors.active1,
        border: 'none',
      },
      hover: {
        color: atoms.colors.primary,
        background: atoms.colors.active3,
        border: 'none',
      },
    },

    secondaryGrey: {
      idle: {
        color: atoms.colors.primary,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.primary}`,
      },

      hover: {
        color: atoms.colors.primary,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.primary}`,
      },

      active: {
        color: atoms.colors.primary,
        background: atoms.colors.layout,
        border: `1px solid ${atoms.colors.primary}`,
      },

      disabled: {
        color: color(atoms.colors.primary).alpha(0.4).string(),
        background: atoms.colors.layout,
        border: `1px solid ${color(atoms.colors.active1).alpha(0.4).string()}`,
      },
    },
  };

  const spammer = {
    Icon: SubscribeLogo,
    background: atoms.colors.spammerBackground,

    [MOBILE]: {
      formElements: {
        radius: '0',
      },
    },

    [DESKTOP]: {
      borderRadius: '0',
      formElements: {
        radius: '0',
        size: 'medium',
      },
    },
  };

  const counter = {
    font: `600 14px/18px ${fonts.text}`,
    color: atoms.colors.primary,
  };

  const stickyPlate = {
    background: color(atoms.colors.active1).lighten(0.4).alpha(0.95).string(),
    close: {
      fill: atoms.colors.active1,
    },
  };

  const commentsPreview = {
    headline: {
      font: `bold 26px/1 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    name: {
      font: `bold 16px/20px ${fonts.text}`,
    },
    date: {
      font: `normal 12px/16px ${fonts.text}`,
    },
    comment: {
      font: `normal 16px/20px ${fonts.text}`,
    },
    button: {
      margin: '30px 0 0 0',
    },
  };

  const ads = {
    urlPreviewImage: {
      retina: require('site/images/macbookPreview-2x.png'),
      common: require('site/images/macbookPreview-1x.png'),
    },
  };

  const colors = {
    adsColor: '#fff',
  };

  const personal = {
    circleCheckMark: {
      stroke: '#000',
    },
    profileAvatar: {
      isCircular: true,
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `700 36px/1.25 ${fonts.text}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `400 36px/1.25 ${fonts.text}`,
      letterSpacing: 'normal',
      color: color(atoms.colors.primary).alpha(0.6).string(),
    },
    lead: {
      ...texts.lead,
    },
    expert: {
      marginTop: '20px',
    },
  };

  const mark = {
    padding: '0.05em 0.1em',
    background: '#FEE9B8',
  };

  const topicFooter = {
    commentButton: {
      type: 'primary',
      font: `400 16px/22px ${fonts.text}`,
      topSpacing: VERTICAL_INDENT,
      icon: {
        width: '30px',
        height: '22px',
        Icon: Comments,
      },
    },
    socialMedia: {
      type: 'fancy',
    },
    block: {
      interitemSpacing: 10,
      spacing: VERTICAL_INDENT,
    },
  };

  const socializator = {
    borderRadius: 0,
  };

  const author = {
    types: {
      expert: {
        background: atoms.colors.active1,
        color: atoms.colors.primary,
        borderRadius: '0',
        textTransform: 'none',
        border: 'none',
      },
    },
  };

  const widgetCaption = {
    textAlign: 'left',
    caption: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.primary400,
    },
    credits: {
      font: `14px/18px ${fonts.text}`,
      color: atoms.colors.primary400,
    },
  };

  const vikontReadMore = {
    block: {
      padding: '15px 30px 36px',
    },
    title: {
      color: atoms.colors.primary400,
      font: `14px/20px ${fonts.text}`,
      marginBottom: '15px',
    },
    drum: {
      itemWidth: 282,
      spaceBetween: 20,
    },
    singleCard: {
      alignItems: 'center',
    },
    feed: {
      interitemSpacing: 15,
    },
  };

  const vikontIncut = {
    borderWidth: '0 0 0 2px',
    borderColor: atoms.colors.active1,
    padding: '20px 40px',
    textAlign: 'left',
  };

  const topicTopline = {
    font: `12px/1 ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.4).string(),
    rubric: {
      color: atoms.colors.primary,
      border: `1px solid ${atoms.colors.primary}`,
      borderRadius: '3px',
      padding: '2px 8px',
      before: {
        content: '# ',
      },
      hover: {
        background: 'transparent',
        color: atoms.colors.active1,
      },
    },
  };

  const notes = {
    background: '#fff6e0',
    borderColor: atoms.colors.active1,
  };

  const errorPage = {
    imgWidth: 360,
    title: {
      font: `bold 100px/136px ${fonts.text}`,
      color: atoms.colors.active1,
    },
    subtitle: {
      font: `bold 32px/44px ${fonts.text}`,
    },
    card: Card3Type1,
  };

  const pages = {
    error: {
      ...errorPage,
      imgUrl: jsErrorImg,
      subtitle: {
        ...errorPage.subtitle,
        name: 'Что-то пошло не так',
      },
    },
    notFound: {
      imgUrl: notFoundImg,
      ...errorPage,
      title: {
        ...errorPage.title,
        name: '404',
      },
      subtitle: {
        ...errorPage.subtitle,
        name: 'Страница куда-то исчезла!',
      },
    },
    sentryFallback: {
      ...errorPage,
      title: {
        ...errorPage.title,
        name: 'Что-то пошло не так',
      },
      subtitle: {
        ...errorPage.subtitle,
        name: 'Мы уже знаем об этом и скоро всё исправим',
      },
      imgUrl: jsErrorImg,
      card: null,
    },
  };

  const topicContent = {
    widgetsMaxWidth: 680,
    widgetsWithNegativeMobileIndent: {
      'snippet': true,
      'smartTeaser': true,
    },
    widgetsWithMaxWidth: {
      'smartTeaser': true,
      'image': true,
      'tableMarkdown': true,
    },
    tableOfContents: {
      maxWidth: '680px',
      margin: '0 auto 30px',
    },
    showErid: false,
  };

  const paginatron = {
    radius: button.const.radius,
  };

  const story = {
    slide: {
      width: 145,
      spaceBetween: 12,
    },
  };

  const storyCard = {
    card: {
      Card: StoryTopicCard,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `600 24px/30px ${fonts.display}`,
      textTransform: 'normal',
    },
    lead: {
      font: `400 14px/18px ${fonts.display}`,
    },
  };

  const storyWidgetsVariants = {
    headline: {
      font: `700 26px/30px ${fonts.display}`,
    },

    states: {
      hover: {
        image: {
          boxShadow: `-4px 4px 0 0 ${atoms.colors.active1}`,
        },
      },
    },

    twoVariants: {
      caption: {
        font: `700 20px/26px ${fonts.display}`,
      },
    },
    threeVariants: {
      caption: {
        font: `700 16px/22px ${fonts.display}`,
      },
    },
  };

  const storyWidgetsRatingCard = {
    headline: {
      font: `700 26px/30px ${fonts.display}`,
    },
    count: {
      font: `92px/1.2 ${fonts.display}`,
    },
    percent: {
      font: `32px/38px ${fonts.display}`,
      bottomIndent: '16px',
    },
  };

  const reactions = {
    tiny: {
      font: `700 14px/16px ${fonts.text}`,
    },
    small: {
      font: `600 14px/16px ${fonts.text}`,
      radius: '13px',
      padding: '3px 7px',
    },

    primary: {
      idle: {
        border: '1px solid transparent',
      },
      checked: {
        color: atoms.colors.active1,
        border: `1px solid ${atoms.colors.active1}`,
        background: 'transparent',
      },
      focus: {
        border: '1px solid transparent',
      },
      disabled: {
        border: '1px solid transparent',
      },
      checkedDisabled: {
        color: atoms.colors.active1,
        border: `1px solid ${atoms.colors.active1}`,
        background: 'transparent',
      },
    },
  };

  const reactionsEmojiBar = {
    const: {
      padding: '24px 15px',
      borderRadius: '8px',
      background: atoms.colors.lightblue1,
      border: `1px solid ${atoms.colors.grey3}`,
      boxShadow: `0 2px 0 0 ${atoms.colors.lightblue2}`,
    },

    title: {
      font: `700 18px/22px ${fonts.display}`,
    },
  };

  const skeleton = {
    background: atoms.colors.placeholder2,
  };

  const gameCompare = {
    showComments: true,
    announce: {
      announceIndent: TopicContentMaxWidth,
      color: color(atoms.colors.primary).alpha(0.6).string(),
    },
    tooltip: {
      theme: 'dark',
      info: {
        fill: atoms.colors.content,
        background: '#747474',
        hover: {
          background: atoms.colors.primary,
        },
      },
      buttonType: 'secondaryGrey',
      scheme: {
        titleFont: `700 18px/30px ${fonts.text}`,
        textFont: `400 18px/26px ${fonts.text}`,
      },
    },
    progressbar: {
      separateText: '/',
      textColor: '#7f6319',
      strokeColor: atoms.colors.active1,
      background: atoms.colors.active1,
    },
    leaderBoard: {
      table: {
        borderColor: '#d9d9d9',
        background: atoms.colors.layout,
        activeBackground: atoms.colors.grey2,
        indent: NegativeMobile,
      },
      count: {
        background: atoms.colors.grey2,
        font: `700 15px/18px ${fonts.display}`,
        activeBackground: atoms.colors.layout,
        activeColor: atoms.colors.primary,
      },
      progressbar: {
        height: '1px',
        progressHeight: '3px',
        background: '#b3b3b3',
        defaultProgress: atoms.colors.primary,
        activeBackground: '#a9a9a9',
        font: `300 12px/17px ${fonts.text}`,
      },
      header: {
        font: `400 12px/14px ${fonts.display}`,
        questionBackground: atoms.colors.grey2,
        color: '#5e5e5e',
      },
    },
    compare: {
      credits: {
        color: atoms.colors.hint,
      },
      caption: {
        background: atoms.colors.primary,
        color: atoms.colors.content,
      },
      draw: {
        backgroundColor: atoms.colors.grey2,
        backgroundText: `linear-gradient(45deg, ${atoms.colors.active1}, ${atoms.colors.active2})`,
      },
      text: {
        background: atoms.colors.grey2,
      },
    },
    compareCard: {
      progressbar: {
        background: atoms.colors.primary,
      },
      lightningColor: atoms.colors.layout,
    },
    share: {
      font: `700 20px/27px ${fonts.text}`,
      marginTop: '30px',
    },
    winner: {
      background: '#2a2a2a',
      borderRadius: '8px',
      imageTop: '30px',
      strokeColor: atoms.colors.content,
      padding: '30px',
      fillColor: atoms.colors.content,
    },
    concidences: {
      font: `400 18px/26px ${fonts.text}`,
      highlight: false,
      margin: '0 0 15px',
    },
  };

  return deepmerge({
    layout,
    fonts,
    icons,
    texts,
    pages,
    controls: {
      counter,
      shapka,
      scooter,
      gallery,
      drum,
      input,
      searchInput,
      bouesque,
      link,
      button,
      spammer,
      stickyPlate,
      commentsPreview,
      personal,
      topicHeaderTexts,
      topicFooter,
      topicContent,
      socializator,
      widgetCaption,
      vikontReadMore,
      vikontIncut,
      topicTopline,
      author,
      notes,
      mark,
      paginatron,
      story,
      storyCard,
      storyWidgetsBaseCard,
      storyWidgetsVariants,
      storyWidgetsRatingCard,
      reactions,
      reactionsEmojiBar,
      skeleton,
      smartTeaser,
      gameCompare,
    },
    ads,
    colors,
  }, atoms);
}
