import PropTypes from 'prop-types';
import cx from 'classnames';

import { Redirect } from 'core/libs/router';
import { isSameYear } from 'core/libs/date-fns';

import resolve from 'core/resolver/resolve';

import { Indent } from 'core/components/Wrappers';
import H1 from 'core/components/H1';
import ButtonWithIcon from 'core/components/ButtonWithIcon';
import Link from 'core/components/Link';
import Page from 'core/components/Page';
import Divider from 'core/components/Divider';

import withTheme from 'core/components/theme';
import withPageHocs from 'core/components/withPageHocs';
import { Desktop, withBreakpoint } from 'core/components/breakpoint';

import {
  getPageDayFromMatch,
  getUrlWithDate,
  getPageDayFromApiLink,
  getDateFromMatch,
} from 'core/utils/url-helper';
import { dateFormat } from 'core/utils/dates';
import { denormalizeData } from 'core/utils/api';

import TopicsList from 'site/components/TopicsList';
import ListPageLayout from 'site/components/ListPageLayout';
import Datepicker from 'site/components/Datepicker';

import ArrowLeft from 'site/icons/ArrowLeftNavigation';
import ArrowRight from 'site/icons/ArrowRightNavigation';

import {
  SMALL_INDENT,
  VERTICAL_INDENT,
  newsBaseUrl,
} from 'site/constants';

import Skeleton from './Skeleton';
import styles from './index.styl';


function NewsPage(props) {
  const {
    rawTopics,
    isDesktop,
    history,
    theme: {
      colors: colorsAtoms,
    },
  } = props;

  const noTopics = !rawTopics?.data?.length;

  const nextLink = rawTopics?.links?.next;
  const nextDateString = getPageDayFromApiLink(nextLink);
  const nextUrl = getUrlWithDate(newsBaseUrl, nextDateString);

  const prevLink = rawTopics?.links?.prev;
  const prevDateString = getPageDayFromApiLink(prevLink);
  const prevUrl = getUrlWithDate(newsBaseUrl, prevDateString);

  if (noTopics) {
    return <Redirect to={nextUrl} />;
  }

  const topics = denormalizeData(rawTopics);
  const firstTopicDateString = topics[0]?.attributes?.published_at;
  const firstTopicDate = new Date(firstTopicDateString);

  const yearFormat = isSameYear(firstTopicDate, new Date()) ? '' : ' y';

  const buttonProps = {
    size: 'medium',
    minWidth: isDesktop ? 125 : 80,
  };

  return (
    <Page
      title='Новости'
      description='Новости'
    >
      <style jsx>{`
        .${styles.data}
          color ${colorsAtoms.hint}
      `}</style>
      <ListPageLayout showRecommender>
        <H1 dataQa='page-title'>
          Новости, <span className={styles.data}>{dateFormat(firstTopicDateString, `d MMMM${yearFormat}`)}</span>
        </H1>
        <Indent top={SMALL_INDENT} />
        <TopicsList topics={topics} />
        <Indent top={VERTICAL_INDENT} />
        <div className={styles.nav}>
          <Desktop>
            <Divider className={cx(styles.divider, styles._left)} />
          </Desktop>
          <Link to={nextUrl} type='secondary'>
            <ButtonWithIcon
              disabled={!nextDateString}
              {...isDesktop && { icon: ArrowLeft }}
              {...buttonProps}
            >
              Назад
            </ButtonWithIcon>
          </Link>
          <div className={styles.dateWrapper}>
            <Datepicker
              value={firstTopicDate && firstTopicDate.toISOString().split('T')[0]}
              onChange={val => {
                const newUrl = getUrlWithDate(newsBaseUrl, val);
                history.push(newUrl);
              }}
            />
          </div>
          <Link to={prevUrl} type='secondary'>
            <ButtonWithIcon
              disabled={!prevDateString}
              {...isDesktop && {
                icon: ArrowRight,
                iconPosition: 'right',
              }}
              {...buttonProps}
            >
              Вперёд
            </ButtonWithIcon>
          </Link>
          <Desktop>
            <Divider className={cx(styles.divider, styles._right)} />
          </Desktop>
        </div>
      </ListPageLayout>
    </Page>
  );
}

NewsPage.propTypes = {
  rawTopics: PropTypes.shape({
    data: PropTypes.array,
    links: PropTypes.object,
  }),
  isDesktop: PropTypes.bool,
  history: PropTypes.object,
  theme: PropTypes.object,
};

/* eslint-disable react/prop-types */
const withRedirect = Component => props => {
  const {
    year,
    month = 1,
    day,
  } = props.match.params;

  // Если мы в корне новостей, то редирект не нужен
  if (!year) return <Component />;

  const date = getDateFromMatch(props.match.params);

  // Для невалидной даты делаем редирект на главную новостей
  // Т.к. у новостей нет подрубрик, сюда же относится случай с
  // попыткой перехода к подрубрикам на /news
  if (!date) {
    return <Redirect to={newsBaseUrl} />;
  }

  // Исправляем дубли вида 2018/1/1 на 2018/01/01
  if (`${month}${day}`.length !== 4) {
    return <Redirect to={getUrlWithDate(newsBaseUrl, date)} />;
  }

  return <Component />;
};
/* eslint-enable react/prop-types */

const dataProvider = resolve({
  rawTopics(props) {
    const {
      bebopApi,
      renderError,
      match,
    } = props;

    return bebopApi.getTopics({
      topic_type: 'news',
      include: 'image,rubric',
      fields: 'published_at,link,headline,comments_count,ad_label',
      'page[day]': getPageDayFromMatch(match.params),
    })
      .catch(renderError);
  },
});

export default withRedirect(
  withPageHocs(dataProvider, Skeleton)(
    withBreakpoint(withTheme(NewsPage))
  )
);
